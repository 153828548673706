import type { Modal } from 'bootstrap'

export const useModal = (id: string, options = {}) => {
  const { $bootstrap } = useNuxtApp()
  const router = useRouter()
  let modal: Modal

  function initiateModal() {
    const modalEl = document.getElementById(id)

    if (modalEl) {
      modal = new $bootstrap.Modal(modalEl, options)
    }
  }

  onMounted(() => {
    initiateModal()

    router.afterEach(() => {
      if (modal) {
        hide()
      }
    })
  })

  function toggle() {
    if (modal) {
      modal.toggle()
    }
  }

  function hide() {
    if (modal) {
      modal.hide()
    }
  }

  function show() {
    if (!modal) {
      initiateModal()
    }
    modal.show()
  }

  return {
    toggle,
    hide,
    show,
  }
}
